<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Отправить приглашение</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="company" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Имя*"
                    v-model="name"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Фамилия*"
                    v-model="surname"
                    :rules="[(v) => !!v || 'Обязательно для заполнения']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    hint="Сюда будет отправлено письмо-приглашение"
                    v-model="email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*обязательные поля</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="close"> Закрыть </v-btn>
          <v-btn color="blue darken-1" text @click="send"> Добавить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "InviteDialog",
  data: () => {
    return {
      valid: true,
      name: "",
      surname: "",
      email: "",
      nameRules: [
        (v) => !!v || "Имя обязателено",
      ],
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Некорректный E-mail'
      ]
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.INVITE_DIALOG;
    },
  },
  methods: {
    validate() {
      this.$refs.company.validate();
    },
    close() {
      this.$store.dispatch('open_invite_dialog', {open: false, company: null})
    },
    send(){
      this.validate()

      if(!this.valid) {
        return
      }
      this.$store.dispatch('send_partner_invite', {
        'name': this.name,
        'surname': this.surname,
        'email': this.email,
      })
    }
  },
};
</script>

<style scoped></style>
