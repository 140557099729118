<template>
  <v-container fluid>
    <company-create-dialog v-on:update="reload_partners" />
    <invite-dialog></invite-dialog>
    <v-row>
      <v-col cols="12" class="">
        <v-row>
          <v-col cols="2">
            <v-btn @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>Назад</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex">
            <v-spacer />
            <v-btn @click="open_create_dialog">Добавить компанию</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="partners"
          item-key="id"
          sort-by="name"
          group-by="company_name"
          :options.sync="options"
          :loading="loading_partners"
          loading-text="Загрузка... Подождите"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 100, -1],
          }"
          show-group-by
        >
          <template
            v-slot:group.header="{ group, headers, toggle, isOpen, items }"
          >
            <td :colspan="headers.length" class="">
              <div class="d-flex align-center">
                <v-btn @click="toggle" x-small icon :ref="group">
                  <v-icon v-if="!isOpen">mdi-plus</v-icon>
                  <v-icon v-else>mdi-minus</v-icon>
                </v-btn>
                <span class="mx-5 font-weight-bold text-capitalize">{{
                  group
                }}</span>
                <v-spacer />
                <v-btn @click="open_invite(items)">Отправить приглашение</v-btn>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import companyCreateDialog from "./CompanyCreateDialog";
import inviteDialog from "./InviteDialog";
export default {
  name: "Company",
  components: {
    companyCreateDialog,
    inviteDialog
  },
  created() {
    this.$store.dispatch("list_partners", this.options);
  },
  data: () => {
    return {
      options: {
        sortBy: ["id"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          groupable: false,
        },
        {
          text: "Имя",
          align: "start",
          value: "name",
          groupable: false,
        },
        {
          text: "Фамилия",
          align: "start",
          value: "surname",
          groupable: false,
        },
        {
          text: "Email",
          align: "start",
          value: "email",
          groupable: false,
        },
        {
          text: "Активен",
          align: "start",
          value: "active",
          groupable: false,
        },
        {
          text: "Компания",
          align: "start",
          sortable: false,
          value: "company_name",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.$store.dispatch("list_partners", this.options);
      },
      deep: true,
    },
  },
  computed: {
    loading_partners() {
      return this.$store.getters.PARTNERS_LOADING;
    },
    partners() {
      const partners = [];

      this.$store.getters.PARTNERS.forEach((company) => {
        if (company.members.length === 0) {
          partners.push({
            id: null,
            name: null,
            surname: null,
            email: null,
            active: null,
            company_name: company.full_name,
            company_id: company.id,
          });
        } else {
          company.members.forEach((partner) =>
            partners.push({
              id: partner.id,
              name: partner.name,
              surname: partner.surname,
              email: partner.email,
              active: partner.active,
              company_name: company.full_name,
              company_id: partner.company_id,
            })
          );
        }
      });
      return partners;
    },
  },
  methods: {
    open_invite(items) {
      this.$store.dispatch('open_invite_dialog', {open: true, company: items[0].company_id})
    },
    send_invite(items) {
      this.$store.dispatch("send_invite", items[0].company_id);
    },
    open_create_dialog() {
      this.$store.dispatch("create_company_dialog", true);
    },
    reload_partners() {
      this.$store.dispatch("list_partners", this.options);
    },
  },
};
</script>

<style scoped></style>
