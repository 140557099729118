var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('company-create-dialog',{on:{"update":_vm.reload_partners}}),_c('invite-dialog'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v("Назад")],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2"}},[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.open_create_dialog}},[_vm._v("Добавить компанию")])],1)],1)],1),_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.partners,"item-key":"id","sort-by":"name","group-by":"company_name","options":_vm.options,"loading":_vm.loading_partners,"loading-text":"Загрузка... Подождите","items-per-page":25,"footer-props":{
          'items-per-page-options': [15, 25, 50, 100, -1],
        },"show-group-by":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
        var group = ref.group;
        var headers = ref.headers;
        var toggle = ref.toggle;
        var isOpen = ref.isOpen;
        var items = ref.items;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(!isOpen)?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold text-capitalize"},[_vm._v(_vm._s(group))]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.open_invite(items)}}},[_vm._v("Отправить приглашение")])],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }