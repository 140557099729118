<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Добавить компанию-партнера</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="company" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="ИНН компании*"
                    v-model="inn"
                    :rules="innRules"
                    :counter="inn_len"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Название компании*"
                    v-model="name"
                    :rules="[(v) => !!v || 'Обязательно для заполнения']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    label="Активна*"
                    v-model="active"
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*обязательные поля</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="close"> Закрыть </v-btn>
          <v-btn color="blue darken-1" text @click="create"> Добавить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CompanyCreateDialog",
  data: () => {
    return {
      valid: true,
      inn: "",
      name: "",
      active: false,
      innRules: [
        (v) => !!v || "ИНН обязателен",
        (v) => (v && v.length <= 12) || "ИНН должен состоять из 12 символов",
      ],
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.COMPANY_DIALOG;
    },
    inn_len() {
      return 12;
    },
  },
  methods: {
    validate() {
      this.$refs.company.validate();
    },
    create() {
      this.validate();
      if (!this.valid) {
        return;
      }
      this.$store
        .dispatch("create_company", {
          inn: this.inn,
          full_name: this.name,
          active: this.active,
        })
        .then(() => {
          this.$emit("update");
          this.close();
        })
        .catch(() => {});
    },
    close() {
      this.$store.dispatch("create_company_dialog", false);
    },
  },
};
</script>

<style scoped></style>
